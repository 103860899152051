<template>
  <div class="max-w-2xl mx-auto space-y-8">
    <div class="font-farnham font-bold text-lg text-center leading-snug text-blue-700" id="modal-title">
      {{ label }}
    </div>

    <div class="space-y-4">
      <template v-for="(item, index) in items" :key="item.value">
        <label
          class="max-w-xl mx-auto relative block px-6 py-4 rounded-2xl shadow-sm cursor-pointer sm:flex sm:justify-between focus:outline-none"
          :class="isSelected(item.value) ? 'bg-blue-50 ring-2 ring-blue-500' : 'bg-white ring-2 ring-gray-200 hover:ring-gray-400'"
          :for="`${name}-${index}`"
        >
          <input
            type="radio"
            class="sr-only"
            :id="`${name}-${index}`"
            :name="name"
            :value="selectedValue"
            :checked="isSelected(item.value)"
            @change="updateSelected(item.value)"
          />

          <div class="flex-1 text-center">
            <p class="text-sm font-semibold text-gray-800">{{ item.label }}</p>
          </div>
        </label>
      </template>
    </div>

    <div class="flex items-center" :class="step == 1 ? 'justify-end': 'justify-between'">
      <a 
        :href="`/sell-your-practice/which-choice-is-right-for-me/${step < 1 ? 1 : step - 1}`"
        class="inline-flex items-center px-4 py-3 text-xs font-semibold tracking-widest uppercase transition duration-150 ease-in-out rounded-md whitespace-nowrap bg-origin-border bg-white text-blue-500 focus:outline-none focus:ring ring-gray-500"
        v-if="step != 1"
      >
        Back
      </a>

      <button
        type="submit"
        class="py-3 px-4 m-1 border border-transparent"
        :class="
          selectedValue
            ? 'btn-primary'
            : 'btn-disabled'
        "
        :disabled="!Boolean(selectedValue)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    name: String,
    items: Array,
    step: {
      type: Boolean,
      default: 1,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      selectedValue: this.value,
    }
  },

  emits: ['update:value'],

  methods: {
    isSelected: function(value) {
      return this.selectedValue === value
    },

    updateSelected: function(value) {
      this.selectedValue = value
      this.$emit('update:value', value);
    }
  }
}
</script>