<template>
  <div>
    <slot name="label">
      <div class="mb-2 text-xs font-medium leading-6 text-gray-800 sm:mb-1" v-show="label">
        {{ label }} <span class="required" v-if="required">*</span>
      </div>
    </slot>

    <fieldset>
      <legend class="sr-only">{{ name.replace('_', ' ') }}</legend>

      <div class="space-y-3 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
        <template v-for="(option, index) in options" :key="index">
          <div class="flex items-center">
            <input
              :id="`${name}_${option.value.toLowerCase()}`"
              :name="name"
              type="radio"
              class="h-5 w-5 focus:ring-blue-500"
              :class="error && !value ? 'border-red-300' : 'border-gray-500 text-blue-500'"
              :value="option.value"
              v-model="value"
              :checked="isSelected(option.value)"
            />

            <label
              :for="`${name}_${option.value.toLowerCase()}`"
              class="ml-3 block text-sm font-medium leading-6"
              :class="error && !value ? 'text-red-500' : 'text-gray-900'"
              >{{ option.label }}</label
            >
          </div>
        </template>
      </div>
    </fieldset>

    <div class="mt-1.5 text-xs text-red-500" v-show="error && !value">{{ error }}</div>

    <!-- Dynamic slots for additional content -->
    <template v-for="option in options" :key="`slot-${option.value}`">
      <div v-if="value === option.value">
        <slot :name="option.value.toLowerCase()"></slot>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: [],
    },
    error: {
      type: String,
      default: '',
    },
    selected: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: this.selected,
    }
  },

  methods: {
    isSelected: function (value) {
      return this.selected === value
    },
  },
}
</script>
