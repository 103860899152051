import './components'
import fslightbox from 'fslightbox'

document.addEventListener('DOMContentLoaded', function () {
  navigation()
  revealOnScroll()
  scrollToHash()
  opportunityDetails()
  accordions()
  blockUI()
})

const navigation = () => {
  const mobileNavOpen = document.getElementById('mobile-nav-open')
  const mobileNavClose = document.getElementById('mobile-nav-close')
  const mobileNav = document.getElementById('mobile-nav')
  const subnavToggles = document.querySelectorAll('.subnav-toggle')
  const menuItems = document.querySelectorAll('.main-menu-item')

  if (menuItems) {
    menuItems.forEach((item) => {
      const submenu = item.nextElementSibling

      if (submenu) {
        const links = item.parentNode.querySelectorAll('a')

        item.addEventListener('click', function (event) {
          event.preventDefault()
        })

        item.addEventListener('mouseenter', function () {
          toggleSubmenu(this, true)
        })

        item.addEventListener('mouseleave', function () {
          toggleSubmenu(this, false)
        })

        item.addEventListener('focus', function () {
          toggleSubmenu(this, true)
        })

        item.addEventListener('blur', function () {
          // Delay the blur handler to check the newly focused element
          setTimeout(() => {
            if (!this.parentNode.contains(document.activeElement)) {
              toggleSubmenu(this, false)
            }
          }, 0)

          const lastSubmenuItem = submenu.querySelector('li:last-child > a')
          if (lastSubmenuItem) {
            lastSubmenuItem.addEventListener('blur', function () {
              setTimeout(() => {
                if (!item.parentNode.contains(document.activeElement)) {
                  toggleSubmenu(item, false)
                }
              }, 0)
            })
          }
        })

        links.forEach((link, index) => {
          link.addEventListener('keydown', function (event) {
            let targetIndex = null
            switch (event.key) {
              case 'ArrowDown':
                targetIndex = index + 1 // Move to the next menu item
                break
              case 'ArrowUp':
                targetIndex = index - 1 // Move to the previous menu item
                break
            }

            if (targetIndex !== null && links[targetIndex]) {
              links[targetIndex].focus() // Set focus to the target menu item
              event.preventDefault() // Prevent the default scrolling behavior
            }
          })
        })
      }
    })

    function toggleSubmenu(element, expand) {
      element.setAttribute('aria-expanded', expand)
    }

    if (mobileNav) {
      mobileNavOpen.addEventListener('click', function () {
        mobileNav.style.transform = 'translate3d(0%, 0, 0)'
        document.body.style.overflow = 'hidden'
      })

      mobileNavClose.addEventListener('click', function () {
        closeMobileNav()
      })

      document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          closeMobileNav()
        }
      })

      subnavToggles.forEach((toggle) => {
        toggle.addEventListener('click', function (event) {
          event.preventDefault()
          toggleSubnav(this)
        })
      })

      function toggleSubnav(toggle) {
        const subnav = toggle.nextElementSibling // This selects the <div> directly following the toggle
        const icon = toggle.querySelector('svg') // Finds the SVG inside the toggle

        // Toggle the hidden class to show/hide the subnav
        subnav.classList.toggle('hidden')

        // Rotate the icon
        // Check if the subnav is now visible by seeing if it does not contain 'hidden'
        icon.style.transform = subnav.classList.contains('hidden') ? 'rotate(0deg)' : 'rotate(90deg)'
      }

      function closeMobileNav() {
        mobileNav.style.transform = ''
        document.body.style.overflow = ''
      }
    }
  }
}

const revealOnScroll = () => {
  const targets = document.querySelectorAll('.reveal-on-scroll')

  if (targets) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fadeIn')
        }
      })
    })

    targets.forEach(function (target) {
      if (!isInitiallyVisible(target)) {
        target.classList.add('opacity-0')
        observer.observe(target)
      }
    })

    function isInitiallyVisible(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom >= 0 &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) &&
        rect.right >= 0
      )
    }
  }
}

const scrollToHash = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const scrollTo = urlParams.get('scrollTo')
  if (scrollTo) {
    let element = document.getElementById(scrollTo)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}

const opportunityDetails = () => {
  var virtualTourContainer = document.getElementById('virtual-tour')

  if (virtualTourContainer) {
    var iframe = virtualTourContainer.querySelector('iframe')
    if (iframe) {
      iframe.onload = function () {
        var span = virtualTourContainer.querySelector('span')
        if (span) {
          span.classList.add('animate-fadeOut')
        }
      }
    }
  }
}

const accordions = () => {
  const buttons = document.querySelectorAll('.accordion-button')

  if (buttons) {
    buttons.forEach((button, index) => {
      button.addEventListener('click', () => {
        const target = document.querySelector(button.getAttribute('data-target'))
        const svgs = button.querySelectorAll('svg')

        if (target.style.height === '0px' || !target.style.height) {
          target.style.height = 'auto'
          const height = target.clientHeight + 'px'
          target.style.height = '0px'

          setTimeout(() => {
            target.style.height = height
            target.classList.remove('opacity-0')
            target.classList.add('opacity-100')
          }, 0)
        } else {
          target.style.height = target.clientHeight + 'px'

          setTimeout(() => {
            target.style.height = '0px'
            target.classList.remove('opacity-100')
            target.classList.add('opacity-0')
          }, 0)
        }

        svgs.forEach((svg) => {
          svg.classList.toggle('opacity-0')
          svg.classList.toggle('pointer-events-none')
        })

        target.addEventListener(
          'transitionend',
          () => {
            if (target.style.height === '0px') {
              target.style.height = '0px'
            } else {
              target.style.height = 'auto'
            }
          },
          { once: true },
        )
      })

      button.addEventListener('keydown', function (event) {
        let targetIndex = null
        switch (event.key) {
          case 'ArrowDown':
            targetIndex = index + 1
            break
          case 'ArrowUp':
            targetIndex = index - 1
            break
        }

        if (targetIndex !== null && buttons[targetIndex]) {
          buttons[targetIndex].focus()
          event.preventDefault()
        }
      })
    })
  }
}

const blockUI = () => {
  const buttons = document.querySelectorAll('[data-block-ui]')

  buttons.forEach((element) => {
    element.addEventListener('click', function () {
      showLoading(element)

      if (element.tagName === 'BUTTON') {
        const form = element.closest('form')
        if (form) {
          form.submit()
        }
      }
    })
  })

  function showLoading(button) {
    // Create the overlay and spinner elements
    const overlay = document.createElement('div')
    overlay.classList.add('block-ui-overlay')
    overlay.innerHTML = '<div class="block-ui-spinner"></div>'
    document.body.appendChild(overlay)

    // Display the overlay
    overlay.style.display = 'flex'

    // Disable the button to prevent further clicks
    button.disabled = true

    // Store the overlay reference in the button's dataset for future removal
    button.dataset.overlay = overlay
  }

  function hideLoading(button) {
    // Retrieve the overlay reference from the button's dataset
    const overlay = button.dataset.overlay

    if (overlay) {
      // Remove the overlay from the DOM
      document.body.removeChild(overlay)

      // Enable the button
      button.disabled = false

      // Remove the dataset reference
      delete button.dataset.overlay
    }
  }
}
