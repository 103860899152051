<template>
  <div :class="error && selectedOptions.length == 0 ? 'has-error' : ''">
    <label class="mb-1 block text-xs font-medium leading-6 text-gray-700" :for="name" v-show="label">
      {{ label }} <span class="required" v-if="required">*</span>
    </label>

    <VueSelect
      v-model="selectedOptions"
      :options="options"
      :placeholder="noneSelectedLabel"
      :multiple="multiple"
      :clearable="clearable"
      :closeOnSelect="!multiple"
      @update:modelValue="updateSelected"
    >
      <template #open-indicator="{ attributes }">
        <span class="far fa-angle-down" :class="error && !selectedOptions ? 'text-red-500' : 'text-gray-600'" v-bind="attributes"></span>
      </template>

      <template #selected-options="{ selectedValue }" v-if="multiple && selectedOptions.length > 0">
        <template v-if="selectedOptions.length < 3">
          <span class="vs__selected" v-for="option in selectedValue" :key="option.value">
            {{ option.label }}

            <button
              type="button"
              :title="`Deselect ${option[label]}`"
              :aria-label="`Deselect ${option[label]}`"
              class="vs__deselect"
              @click="deselect(option.value)"
              v-if="!disabled"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                ></path>
              </svg>
            </button>
          </span>
        </template>
        <span class="vs__selected" v-else>{{ selectedOptions.length }} items selected</span>
      </template>

      <template #option="option" v-if="multiple && selectedOptions.length > 0">
        <div class="flex items-center justify-between gap-3">
          <slot name="option" :option="option">
            {{ option.label }}
          </slot>

          <button
            type="button"
            class="flex px-3 text-white hover:text-white"
            @click="deselectIfSelected($event, option.value)"
            v-if="selectedOptions && selectedOptions.filter((o) => o.value === option.value).length > 0"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path d="M6 6 L18 18 M18 6 L6 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" />
            </svg>
          </button>
        </div>
      </template>

      <template #list-header v-if="multiple">
        <li class="flex justify-center gap-2 border-b border-gray-200 !pb-1">
          <a role="button" class="btn-primary btn-sm" @click="selectAll">Select All</a>
          <a role="button" class="btn-primary btn-sm" @click="deselectAll">Deselect All</a>
        </li>
      </template>
    </VueSelect>

    <input type="hidden" :name="name" :value="currentSelection" />

    <div class="mt-1.5 text-xs text-red-500" v-show="error && selectedOptions.length == 0">{{ error }}</div>

    <slot name="label-footer"></slot>
  </div>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance } from 'vue'
import VueSelect from 'vue-select'

const props = defineProps({
  clearable: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  name: String,
  noneSelectedLabel: {
    type: String,
    default: 'Select one',
  },
  options: {
    type: Array,
    default: () => [],
  },
  required: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: [String, Array],
    default: () => null,
  },
})

const selectedOptions = ref(
  props.options.filter((option) =>
    props.multiple
      ? props.selected && props.selected.split(',').find((selected) => selected === option.value.toString())
      : option.value === props.selected.toString(),
  ),
)
const instance = getCurrentInstance()

const currentSelection = computed(() => {
  let current = selectedOptions.value

  if (props.multiple) {
    return Array.isArray(current) ? current.map((item) => item.value).join(',') : ''
  } else {
    return Array.isArray(current) && current.length > 0 ? current[0].value : current?.value
  }
})

watch(selectedOptions, (newValue) => {
  instance.emit('update:selected', newValue)
})

function deselect(value) {
  selectedOptions.value = selectedOptions.value.filter((o) => o.value !== value)
}

function deselectIfSelected(e, value) {
  e.stopPropagation()

  selectedOptions.value = selectedOptions.value.filter((o) => o.value !== value)
}

function deselectAll() {
  selectedOptions.value = []
}

function selectAll() {
  selectedOptions.value = props.options
}

function updateSelected(newValue) {
  selectedOptions.value = newValue
}
</script>

<style lang="scss">
:root {
  --vs-colors--lightest: rgba(60, 60, 60, 0.26);
  --vs-colors--light: rgba(60, 60, 60, 0.5);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 0.15);
  --vs-search-input-color: inherit;
  --vs-search-input-placeholder-color: inherit;
  --vs-font-size: 1rem;
  --vs-line-height: 1.4;
  --vs-state-disabled-bg: rgb(248, 248, 248);
  --vs-state-disabled-color: var(--vs-colors--light);
  --vs-state-disabled-controls-color: var(--vs-colors--light);
  --vs-state-disabled-cursor: not-allowed;
  --vs-border-color: var(--vs-colors--lightest);
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-radius: 4px;
  --vs-actions-padding: 4px 6px 0 3px;
  --vs-controls-color: theme('colors.gray.400');
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;
  --vs-selected-bg: theme('colors.gray.100');
  --vs-selected-color: theme('colors.gray.700');
  --vs-selected-border-color: theme('colors.gray.300');
  --vs-selected-border-style: solid;
  --vs-selected-border-width: 1px;
  --vs-dropdown-bg: #fff;
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 350px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--vs-dropdown-color);
  --vs-dropdown-option-padding: 0.5rem 0 0.5rem 1rem;
  --vs-dropdown-option--active-bg: theme('colors.gray.200');
  --vs-dropdown-option--active-color: theme('colors.gray.900');
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 0.15s;
}
.v-select {
  @apply text-base;
  position: relative;
  font-family: inherit;
}
.v-select,
.v-select * {
  box-sizing: border-box;
}
:root {
  --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
  --vs-transition-duration: 0.15s;
}
@keyframes vSelectSpinner {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.vs__fade-enter-active,
.vs__fade-leave-active {
  pointer-events: none;
  transition: opacity var(--vs-transition-duration) var(--vs-transition-timing-function);
}
.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}
:root {
  --vs-disabled-bg: var(--vs-state-disabled-bg);
  --vs-disabled-color: var(--vs-state-disabled-color);
  --vs-disabled-cursor: var(--vs-state-disabled-cursor);
}
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: var(--vs-disabled-cursor);
  background-color: var(--vs-disabled-bg);
}
.v-select[dir='rtl'] .vs__actions {
  padding: 0 3px 0 6px;
}
.v-select[dir='rtl'] .vs__clear {
  margin-left: 6px;
  margin-right: 0;
}
.v-select[dir='rtl'] .vs__deselect {
  margin-left: 0;
  margin-right: 2px;
}
.v-select[dir='rtl'] .vs__dropdown-menu {
  text-align: right;
}
.vs__dropdown-toggle {
  @apply flex min-h-[3rem] w-full rounded-2xl border-0 bg-white px-2 py-2 ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-gray-400 focus:outline-none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  white-space: normal;
}
.has-error .vs__dropdown-toggle {
  @apply bg-red-50 text-red-500 ring-2 ring-red-300;
}
.vs__selected-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
}

.vs__actions {
  display: flex;
  align-items: center;
  padding: var(--vs-actions-padding);
}
.vs--searchable .vs__dropdown-toggle {
  cursor: text;
}
.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}
.vs--open .vs__dropdown-toggle {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vs__open-indicator {
  fill: var(--vs-controls-color);
  transform: scale(var(--vs-controls-size));
  transition: transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  transition-timing-function: var(--vs-transition-timing-function);
}
.vs--open .vs__open-indicator {
  transform: rotate(180deg) scale(var(--vs-controls-size));
}
.vs--loading .vs__open-indicator {
  opacity: 0;
}
.vs__clear {
  fill: var(--vs-controls-color);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 15px;
}
.vs__dropdown-menu {
  @apply rounded-b-2xl ring-2 ring-gray-400;
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - var(--vs-border-width));
  left: 0;
  z-index: var(--vs-dropdown-z-index);
  padding: 5px 0 10px;
  margin: 0 !important;
  width: 100%;
  max-height: var(--vs-dropdown-max-height);
  min-width: var(--vs-dropdown-min-width);
  overflow-y: auto;
  text-align: left;
  list-style: none;
  border: none;
  background: var(--vs-dropdown-bg);
  color: var(--vs-dropdown-color);
}
.vs__no-options {
  text-align: center;
}
.vs__dropdown-option {
  line-height: 1.42857143;
  display: block;
  padding: var(--vs-dropdown-option-padding) !important;
  clear: both;
  color: var(--vs-dropdown-option-color);
  white-space: nowrap;
  cursor: pointer;

  &.vs__dropdown-option--selected {
    @apply text-white;
    background-color: #33527d;
  }
}
.vs__dropdown-option--highlight {
  background: var(--vs-dropdown-option--active-bg);
  color: var(--vs-dropdown-option--active-color);
}
.vs__dropdown-option--deselect {
  background: var(--vs-dropdown-option--deselect-bg);
  color: var(--vs-dropdown-option--deselect-color);
}
.vs__dropdown-option--disabled {
  background: var(--vs-state-disabled-bg);
  color: var(--vs-state-disabled-color);
  cursor: var(--vs-state-disabled-cursor);
}
.vs__selected {
  @apply min-h-[32px] rounded-lg bg-blue-200/60;
  display: flex;
  align-items: center;
  border: var(--vs-selected-border-width) var(--vs-selected-border-style) var(--vs-selected-border-color);
  color: var(--vs-selected-color);
  line-height: var(--vs-line-height);
  margin: 0;
  padding: 0 0.5em;
  z-index: 0;
}
.vs__deselect {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 6px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: var(--vs-controls-color);
  text-shadow: var(--vs-controls--deselect-text-shadow);
}
.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}
.vs--single.vs--open .vs__selected,
.vs--single.vs--loading .vs__selected {
  position: absolute;
  opacity: 0.4;
}
.vs--single.vs--searching .vs__selected {
  display: none;
}
.vs__search::-webkit-search-cancel-button {
  display: none;
}
.vs__search::-webkit-search-decoration,
.vs__search::-webkit-search-results-button,
.vs__search::-webkit-search-results-decoration,
.vs__search::-ms-clear {
  display: none;
}
.vs__search,
.vs__search:focus {
  color: var(--vs-search-input-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: var(--vs-line-height);
  font-size: var(--vs-font-size);
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}
.vs__search::-moz-placeholder {
  color: var(--vs-search-input-placeholder-color);
}
.vs__search::placeholder {
  color: var(--vs-search-input-placeholder-color);
}
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}
.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0.2;
}
.vs__spinner {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 0.9em solid rgba(100, 100, 100, 0.1);
  border-right: 0.9em solid rgba(100, 100, 100, 0.1);
  border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
  border-left: 0.9em solid rgba(60, 60, 60, 0.45);
  transform: translateZ(0) scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  animation: vSelectSpinner 1.1s infinite linear;
  transition: opacity 0.1s;
}
.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
}
.vs--loading .vs__spinner {
  opacity: 1;
}
</style>
