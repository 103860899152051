import { createApp } from 'vue'
import Accordion from './components/Accordion.vue'
import Faqs from './components/Faqs.vue'
import PasswordWithStrengthIndicator from './components/PasswordWithStrengthIndicator.vue'
import RadioButtonList from './components/RadioButtonList.vue'
import RadioButtonSelectionStep from './components/RadioButtonSelectionStep.vue'
import SavingsCalculator from './components/SavingsCalculator.vue'
import Textbox from './components/Textbox.vue'
import vSelect from './components/Select.vue'

const el = document.getElementById('app')
const app = createApp({
  components: {
    Accordion: Accordion,
    Faqs: Faqs,
    PasswordWithStrengthIndicator: PasswordWithStrengthIndicator,
    RadioButtonList: RadioButtonList,
    RadioButtonSelectionStep: RadioButtonSelectionStep,
    SavingsCalculator: SavingsCalculator,
    Textbox: Textbox,
    vSelect: vSelect,
  },
})
app.mount(el)
