<template>
  <div>
    <div v-for="(faq, index) in faqs" class="accordion-item mb-4" :class="{ active: index == selected }" :key="faq.id">
      <div role="heading" aria-level="1">
        <div
          role="button"
          class="accordion-header group flex items-center border-b pb-4"
          aria-expanded="false"
          :aria-controls="`faq-${faq.id}`"
          @click="selected = selected == index ? null : index"
        >
          <div
            class="mr-4 flex h-6 w-6 flex-shrink-0 cursor-pointer items-center justify-center rounded-full border border-gray-500 text-xs font-bold leading-none group-hover:border-blue-200 group-hover:text-blue-200"
            aria-hidden="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="16"
              viewBox="0 0 12 16"
              class="icon-expand fill-current"
              v-if="index != selected"
            >
              <path d="M12 9H7v5H5V9H0V7h5V2h2v5h5z"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="16"
              viewBox="0 0 12 16"
              class="icon-collapse fill-current"
              v-if="index == selected"
            >
              <path
                d="M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48z"
              ></path>
            </svg>
          </div>
          <div
            :id="`faq-${faq.id}-label`"
            class="mb-0 w-full cursor-pointer text-lg font-semibold leading-tight text-black group-hover:text-blue-500"
          >
            <div class="flex flex-col justify-end gap-2 sm:flex-row sm:items-center sm:gap-8">
              <span class="flex-1">{{ faq.question }}</span>
              <div class="w-20 rounded bg-blue-50 px-2 pb-1 pt-2 shadow" v-if="faq.post">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 636.5 294.03">
                  <g>
                    <path
                      fill="#1f3b70"
                      d="M126.01,74.92h35.8l8.94,25.24h13.35L150.59,6.68h-13.35l-33.52,93.48h13.35l8.94-25.24ZM143.91,24.37l13.83,39.06h-27.67l13.83-39.06Z"
                    />
                    <path
                      fill="#1f3b70"
                      d="M220.55,88.67c-2.25,1.43-5.49,2.14-9.71,2.14-4.99,0-9.28-.8-12.89-2.4-3.61-1.6-6.12-3.03-7.55-4.27l-1.47,11.62c1.56,1.07,4.24,2.29,8.05,3.67,3.81,1.38,8.6,2.07,14.39,2.07,6.68,0,11.82-1.02,15.42-3.07,3.61-2.05,6.1-4.57,7.48-7.58,1.38-3,2.07-5.95,2.07-8.85,0-3.43-.66-6.29-1.97-8.58-1.31-2.29-2.97-4.15-4.97-5.58-2-1.42-4.06-2.56-6.18-3.41-2.11-.84-3.97-1.49-5.58-1.94-5.12-1.56-8.94-2.98-11.45-4.27-2.52-1.29-3.77-3.47-3.77-6.54s1.18-5.31,3.54-6.71c2.36-1.4,5.41-2.1,9.15-2.1s6.81.56,9.35,1.67c2.54,1.11,4.76,2.47,6.68,4.07l1.47-10.95c-2-1.02-4.37-1.99-7.11-2.9-2.74-.91-6.29-1.37-10.65-1.37-5.97,0-10.75.93-14.36,2.8-3.61,1.87-6.21,4.31-7.81,7.31-1.6,3.01-2.4,6.18-2.4,9.51,0,4.41,1.09,7.84,3.27,10.28,2.18,2.45,4.86,4.28,8.05,5.51,3.18,1.22,6.26,2.24,9.25,3.04,1.78.49,3.68,1.15,5.71,1.97,2.02.82,3.76,1.94,5.21,3.34,1.45,1.4,2.17,3.24,2.17,5.51,0,2.58-1.12,4.59-3.37,6.01Z"
                    />
                    <polygon
                      fill="#1f3b70"
                      points="270.04 65.17 296.08 34.72 281.39 34.72 257.35 64.23 257.35 0 245.33 0 245.33 100.15 257.35 100.15 257.35 66.9 282.05 100.15 296.74 100.15 270.04 65.17"
                    />
                    <path
                      fill="#1f3b70"
                      d="M352.83,17.36l-12.02,1.2v16.16h-10.15l-1.07,9.35h11.22v41.8c0,2.63.57,5.13,1.7,7.51,1.14,2.38,2.95,4.33,5.44,5.84,2.49,1.51,5.81,2.27,9.95,2.27h10.82l.93-9.61h-3.07c-4.32,0-7.44-.24-9.38-.73-1.94-.49-3.15-1.67-3.64-3.54-.49-1.87-.73-4.9-.73-9.08v-34.45h17.23v-9.35h-17.23v-17.36Z"
                    />
                    <path
                      fill="#1f3b70"
                      d="M389.28,53.01c1.56-2.21,3.41-4.09,5.58-5.6,3.18-2.23,6.84-3.34,10.98-3.34,5.48,0,9.54,1.71,12.18,5.14,2.65,3.43,3.97,8.75,3.97,15.96v34.99h12.02v-36.46c0-6.05-.95-11.35-2.84-15.89-1.89-4.54-4.73-8.08-8.51-10.62-3.78-2.54-8.5-3.81-14.16-3.81s-10.05,1.1-13.59,3.3c-1.95,1.22-3.83,2.7-5.64,4.42V0h-12.15v100.15h12.15v-47.15Z"
                    />
                    <path
                      fill="#1f3b70"
                      d="M498.69,86.93c-2.94,1.34-6.05,2.32-9.35,2.94-3.3.62-6.45.93-9.48.93-4.14,0-7.97-.69-11.48-2.07-3.52-1.38-6.43-3.76-8.75-7.14-2.31-3.38-3.76-8.1-4.34-14.16h47.41c.22-1.16.37-2.28.43-3.37.07-1.09.1-2.1.1-3.04,0-5.88-1.21-10.87-3.64-14.99-2.43-4.12-5.77-7.26-10.02-9.41-4.25-2.16-9.09-3.24-14.52-3.24-6.77,0-12.54,1.53-17.33,4.57-4.79,3.05-8.44,7.11-10.95,12.18-2.52,5.07-3.77,10.66-3.77,16.76s1.35,12.03,4.04,17.26c2.69,5.23,6.67,9.43,11.92,12.59,5.25,3.16,11.73,4.74,19.43,4.74,4.76,0,8.97-.57,12.62-1.7,3.65-1.14,6.74-2.26,9.28-3.37l-1.6-9.48ZM462.47,48.04c2.87-2.65,6.98-3.97,12.32-3.97,3.65,0,6.65.61,9.01,1.84,2.36,1.22,4.12,2.97,5.27,5.24,1.16,2.27,1.74,4.94,1.74,8.01h-34.45c1.2-4.76,3.24-8.47,6.11-11.12Z"
                    />
                    <polygon
                      fill="#00abec"
                      points="27.12 181.21 78.04 181.21 78.04 155.54 27.12 155.54 27.12 124.7 92.74 124.7 92.74 99.03 0 99.03 0 243.93 94.81 243.93 94.81 218.26 27.12 218.26 27.12 181.21"
                    />
                    <polygon
                      fill="#00abec"
                      points="169.74 140.43 152.35 170.45 133.51 140.43 102.88 140.43 137.24 192.18 102.88 243.93 133.51 243.93 150.9 213.92 169.74 243.93 200.37 243.93 166.01 192.18 200.37 140.43 169.74 140.43"
                    />
                    <path
                      fill="#00abec"
                      d="M289.86,146.12c-6.9-4.9-15.42-7.35-25.56-7.35-8.07,0-14.9,1.47-20.49,4.4-3.53,1.85-6.89,4.1-10.09,6.74l-2.74-9.48h-20.7v153.59h25.67v-56.41c2.92,2.11,5.82,3.82,8.69,5.08,5.04,2.21,11.59,3.31,19.67,3.31,10.14,0,18.66-2.45,25.56-7.35,6.9-4.9,12.13-11.42,15.68-19.56,3.55-8.14,5.33-17.04,5.33-26.7s-1.78-18.56-5.33-26.7c-3.55-8.14-8.78-14.66-15.68-19.56ZM278.22,215.83c-4.24,6.04-10.19,9.06-17.85,9.06-5.94,0-10.8-1.38-14.59-4.14-3.8-2.76-6.59-6.57-8.38-11.44-1.79-4.87-2.69-10.51-2.69-16.92s.9-12.06,2.69-16.92c1.79-4.86,4.59-8.68,8.38-11.44,3.79-2.76,8.66-4.14,14.59-4.14,7.66,0,13.61,3,17.85,9,4.24,6,6.37,13.83,6.37,23.49s-2.12,17.41-6.37,23.44Z"
                    />
                    <path
                      fill="#00abec"
                      d="M394.54,144.88c-6.62-4.07-14.9-6.11-24.84-6.11-10.83,0-20.01,2.43-27.53,7.3-7.52,4.87-13.23,11.3-17.13,19.3-3.9,8.01-5.85,16.73-5.85,26.19s2.07,18.82,6.21,27.07c4.14,8.25,10.37,14.87,18.68,19.87,8.31,5,18.72,7.5,31.21,7.5,7.38,0,14.01-.79,19.87-2.38,5.86-1.59,10.76-3.42,14.7-5.49l-1.24-21.73c-4.35,2.55-9.11,4.61-14.28,6.16-5.17,1.55-10.76,2.33-16.77,2.33-5.11,0-9.88-.93-14.33-2.79-4.45-1.86-8.21-4.78-11.28-8.75-3.07-3.97-5.09-9.16-6.05-15.58h66.86c.55-2.48.97-4.88,1.24-7.19.28-2.31.41-4.61.41-6.88,0-8.21-1.66-15.71-4.97-22.51-3.31-6.79-8.28-12.23-14.9-16.3ZM346.51,180.38c1.24-5.93,3.76-10.83,7.56-14.7,3.79-3.86,9-5.8,15.63-5.8,4.62,0,8.3,1.07,11.02,3.21,2.73,2.14,4.67,4.78,5.85,7.92,1.17,3.14,1.76,6.26,1.76,9.37h-41.81Z"
                    />
                    <path
                      fill="#00abec"
                      d="M463.57,142.71c-4.76,2.9-8.45,5.8-11.07,8.69l-.62-10.97h-24.01v103.5h25.67v-70.59c1.45-2.9,3.97-5.35,7.56-7.35,3.59-2,7.56-3,11.9-3,1.45,0,2.91.07,4.4.21,1.48.14,2.98.41,4.5.83l1.86-25.25c-1.38-.28-2.9-.41-4.55-.41-5.66,0-10.87,1.45-15.63,4.35Z"
                    />
                    <path
                      fill="#00abec"
                      d="M528.9,114.97l-25.67,2.69v22.77h-12.42l-2.28,21.11h14.7v57.34c0,4.49.97,8.8,2.9,12.94,1.93,4.14,4.93,7.54,9,10.2,4.07,2.66,9.35,3.98,15.84,3.98h18.42l2.28-21.11h-7.04c-4.83,0-8.35-.29-10.56-.88-2.21-.58-3.62-2.03-4.24-4.35-.62-2.31-.93-6.09-.93-11.33v-46.78h22.77v-21.11h-22.77v-25.46Z"
                    />
                    <path
                      fill="#00abec"
                      d="M634.79,204.55c-1.14-3.07-2.88-5.81-5.23-8.23-2.35-2.42-5.3-4.62-8.85-6.62-3.55-2-7.75-3.93-12.58-5.8-7.45-2.9-12.87-5.35-16.25-7.35-3.38-2-5.07-5-5.07-9,0-3.31,1.21-5.86,3.62-7.66,2.41-1.79,5.83-2.69,10.25-2.69,5.59,0,10.73,1.05,15.42,3.16,4.69,2.11,9.35,4.78,13.97,8.02l2.28-21.94c-4.42-2.48-9.23-4.45-14.44-5.9-5.21-1.45-11.09-2.17-17.65-2.17-7.87,0-14.66,1.28-20.39,3.83-5.73,2.55-10.14,6.11-13.25,10.66-3.1,4.55-4.66,9.8-4.66,15.73s1.15,10.54,3.47,14.44c2.31,3.9,5.69,7.23,10.14,9.99,4.45,2.76,9.85,5.31,16.2,7.66,4.21,1.59,7.73,3.07,10.56,4.45,2.83,1.38,4.95,2.93,6.37,4.66,1.41,1.72,2.12,3.93,2.12,6.62,0,3.59-1.35,6.21-4.04,7.87-2.69,1.66-6.59,2.48-11.7,2.48-5.66,0-11.06-.95-16.2-2.85-5.14-1.9-10.37-4.4-15.68-7.5l-2.28,21.94c5.52,2.48,11.02,4.38,16.51,5.69,5.49,1.31,11.64,1.97,18.47,1.97,8.69,0,16.06-1.21,22.1-3.62,6.04-2.41,10.62-5.92,13.77-10.5,3.14-4.59,4.71-10.16,4.71-16.71,0-4-.57-7.54-1.71-10.61Z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :id="`faq-${faq.id}`"
        role="region"
        class="accordion-content space-y-4 overflow-hidden border-b px-4 leading-relaxed opacity-0 transition-all duration-200 ease-in-out"
        :class="{ '!max-h-full pb-8 pt-4 opacity-100 duration-300': index == selected }"
        style="max-height: 0"
        :aria-labelledby="`faq-${faq.id}-label`"
      >
        <div class="rounded-lg bg-gray-50 px-4 py-2 ring-1 ring-gray-300" v-if="faq.post">
          <p>
            This question was asked by <span class="font-bold">{{ faq.post.metadata.video_asker }}</span> as a part of our
            <a class="font-bold" href="/why-choice/ask-the-experts">Ask the Experts video series</a>.
          </p>
        </div>

        <div v-html="faq.answer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faqs: Array,
  },

  data() {
    return {
      selected: null,
    }
  },

  methods: {},
}
</script>

<style>
.accordion-content p:last-child {
  margin-bottom: 0;
}
</style>
