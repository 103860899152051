<template>
  <div
    id="savings-calculator"
    class="md:py-18 relative z-10 block snap-start scroll-mt-[105px] overflow-hidden bg-cover bg-center bg-repeat p-3 px-8 py-12 before:absolute before:inset-0 before:z-[-5] before:block before:bg-blue-50 before:mix-blend-multiply before:content-['']"
    style="background-image: url('/images/static/diamonds-bg-transparent.jpg')"
  >
    <div class="mx-auto max-w-7xl">
      <h2 class="text-center">How much will you save by selling with Choice Transitions?</h2>

      <div class="flex flex-col gap-6">
        <div class="mx-auto rounded-2xl bg-white p-6 shadow lg:w-1/3">
          <div class="font-farnham text-center text-lg font-semibold">Selling to a</div>

          <div class="mt-2 flex items-center justify-center">
            <span class="mr-3" id="Traditional Label">
              <span class="text-sm font-medium transition-colors duration-200 ease-in-out" :class="traditional ? 'text-orange-500' : ''"
                >Dentist</span
              >
            </span>
            <button
              type="button"
              class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-gray-300 focus:ring-offset-2"
              :class="traditional ? 'border-orange-500 bg-orange-500' : 'border-brand-green bg-brand-green'"
              role="switch"
              aria-checked="false"
              aria-labelledby="annual-billing-label"
              @click="traditional = !traditional"
            >
              <span
                aria-hidden="true"
                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                :class="traditional ? 'translate-x-0' : 'translate-x-5'"
              ></span>
            </button>
            <span class="ml-3" id="DSO Label">
              <span class="text-sm font-medium transition-colors duration-200 ease-in-out" :class="!traditional ? 'text-brand-green' : ''"
                >DSO</span
              >
            </span>
          </div>
        </div>

        <div class="flex w-full flex-col items-center gap-8 rounded-2xl bg-white p-6 shadow md:flex-row md:justify-between">
          <div class="w-full lg:w-1/2">
            <div class="font-farnham pb-3 text-center font-semibold">Practice Sale Price</div>

            <range-slider
              v-model="practice_sale_price"
              :range="traditional ? [0, 2000000] : [0, 20000000]"
              :increment="traditional ? 25000 : 100000"
            ></range-slider>
          </div>

          <div class="flex flex-col justify-between gap-8 sm:flex-row">
            <div>
              <div class="font-farnham text-center font-semibold">Choice's Commission</div>
              <div class="font-farnham text-2xs text-center">{{ traditional ? 'as low as 3' : '0' }}%</div>
              <div class="text-center text-xl font-semibold text-blue-700">{{ formatCurrency(practice_choice_commission) }}</div>
            </div>
            <div>
              <div class="font-farnham text-center font-semibold">Competitors' Commission</div>
              <div class="font-farnham text-2xs text-center">10%</div>
              <div class="text-center text-xl font-semibold text-blue-700">{{ formatCurrency(practice_other_commission) }}</div>
            </div>
          </div>
        </div>

        <div class="flex w-full flex-col items-center gap-6 rounded-2xl bg-white p-6 shadow md:flex-row md:justify-between">
          <div class="w-full lg:w-1/2" id="slider">
            <div class="font-farnham pb-3 text-center font-semibold">Real Estate Price</div>

            <range-slider
              v-model="real_estate_price"
              :range="traditional ? [0, 2000000] : [0, 20000000]"
              :increment="traditional ? 25000 : 100000"
            ></range-slider>
          </div>
          <div class="flex flex-col justify-between gap-6 sm:flex-row">
            <div>
              <div class="font-farnham text-center font-semibold">Choice's Commission</div>
              <div class="font-farnham text-2xs text-center">0%</div>
              <div class="text-center text-xl font-semibold text-blue-700">{{ formatCurrency(real_estate_choice_commission) }}</div>
            </div>
            <div>
              <div class="font-farnham text-center font-semibold">Competitors' Commission</div>
              <div class="font-farnham text-2xs text-center">6%</div>
              <div class="text-center text-xl font-semibold text-blue-700">{{ formatCurrency(real_estate_other_commission) }}</div>
            </div>
          </div>
        </div>
        <div
          class="w-full items-center justify-center space-y-1 rounded-2xl bg-white px-6 pb-5 pt-6 text-center shadow md:flex md:gap-2 md:space-y-0"
        >
          <div class="font-farnham text-xl font-semibold">Selling With Choice Saves You</div>
          <div class="block text-4xl font-semibold leading-none text-blue-400">{{ formatCurrency(total_savings) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from './RangeSlider.vue'

export default {
  components: { RangeSlider },

  data() {
    return {
      traditional: true,
      practice_sale_price: 750000,
      real_estate_price: 1200000,
    }
  },

  computed: {
    practice_choice_commission: function () {
      return this.practice_sale_price * (this.traditional ? 0.03 : 0)
    },

    practice_other_commission: function () {
      return this.practice_sale_price * 0.1
    },

    real_estate_choice_commission: function () {
      return this.real_estate_price * 0
    },

    real_estate_other_commission: function () {
      return this.real_estate_price * 0.06
    },

    total_savings: function () {
      return (
        this.practice_other_commission +
        this.real_estate_other_commission -
        this.practice_choice_commission -
        this.real_estate_choice_commission
      )
    },
  },

  watch: {
    traditional: function (isTraditional) {
      this.practice_sale_price = isTraditional ? 750000 : 7500000
      this.real_estate_price = isTraditional ? 1200000 : 12000000
    },
  },

  methods: {
    formatCurrency(str) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })

      return formatter.format(str)
    },
  },
}
</script>
