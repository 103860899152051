<template>
  <div class="bg-white mx-auto border border-gray-500 rounded overflow-hidden">
    <ul class="nobullet">
      <li v-for="(pane, index) in data" class="relative border-b border-gray-500" :key="index">
        <button
          type="button"
          class="w-full px-6 py-3 text-left"
          :class="
            selected == index + 1 ? 'bg-blue-700 text-white border-0 border-b border-solid border-gray-500' : 'bg-gray-100 text-gray-900'
          "
          @click="selected !== index + 1 ? (selected = index + 1) : (selected = null)"
        >
          <div class="flex items-center justify-between">
            <span class="normal-case font-bold">{{ pane.title }}</span>
            <span class="fa ml-4" :class="selected == index + 1 ? 'fa-minus' : 'fa-plus'"></span>
          </div>
        </button>

        <transition name="accordion" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
          <div class="relative overflow-hidden transition-all duration-700" v-show="selected == index + 1">
            <div class="flex flex-col items-center p-6 sm:flex-row">
              <div class="order-2 sm:order-1">
                <p class="leading-relaxed mb-0">{{ pane.content }}</p>
              </div>

              <div class="flex-shrink-0 mb-4 order-1 sm:order-2 sm:mb-0 sm:ml-12" v-if="pane.icon">
                <img class="w-20" :src="pane.icon" alt="" />
              </div>
            </div>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },

  data() {
    return {
      selected: null,
    }
  },

  mounted() {
    this.selected = 1
  },

  methods: {
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      el.style.height = '0'
    },
  },
}
</script>

<style>
.max-h-0 {
  max-height: 0;
}
</style>