<template>
  <div class="sm:col-span-3">
    <Textbox
      :type="passwordVisible ? 'text' : 'password'"
      name="password"
      label="Password"
      :error="error"
      v-model="password"
      required
      autocomplete="on"
    >
      <template #trailing-addon>
        <button type="button" class="flex items-center justify-center" tabindex="-1" @click="test">
          <i class="fas" :class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </template>
    </Textbox>

    <div class="flex space-x-2 pt-4 md:space-x-4">
      <template v-for="(requirement, index) in requirements" :key="index">
        <div
          class="h-2 w-full rounded-full"
          :class="requirements.filter((requirement) => requirement.complete).length >= index + 1 ? 'bg-brand-green' : 'bg-gray-300'"
        ></div>
      </template>
    </div>
  </div>

  <div class="sm:col-span-3">
    <Textbox
      :type="confirmPasswordVisible ? 'text' : 'password'"
      name="password_confirmation"
      label="Confirm Password"
      v-model="confirmPassword"
      :error="confirmationError"
      required
      autocomplete="off"
      @blur="checkPasswordConfirmation"
    >
      <template #trailing-addon>
        <button
          type="button"
          class="flex items-center justify-center"
          tabindex="-1"
          @click="confirmPasswordVisible = !confirmPasswordVisible"
        >
          <i class="fas" :class="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </template>
    </Textbox>
  </div>

  <div class="col-span-6">
    <p class="!mb-2 text-xs font-semibold">Your password must contain:</p>

    <div class="space-y-1 text-xs text-gray-500">
      <template v-for="(requirement, index) in requirements" :key="index">
        <div class="flex items-center gap-x-2">
          <svg class="h-4 w-4 shrink-0 text-center text-brand-green" viewBox="0 0 512 512" v-if="requirement.complete">
            <path
              fill="currentColor"
              d="M173.9 439.4l-166.4-166.4c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.7 432.1 72.6c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2l-294.4 294.4c-10 10-26.2 10-36.2 0z"
            ></path>
          </svg>

          <svg
            class="size-4 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            v-else
          >
            <path d="M18 6 6 18"></path>
            <path d="m6 6 12 12"></path>
          </svg>

          <span :class="{ 'text-brand-green': requirement.complete }">{{ requirement.label }}</span>
        </div>
      </template>
    </div>
  </div>

  <div class="col-span-6 flex justify-end gap-6">
    <button type="submit" class="btn" :class="disabled ? 'btn-disabled' : submitButtonType || 'btn-primary'" :disabled="disabled">
      {{ submitButtonText || 'Next' }}
    </button>
  </div>
</template>

<script>
import Textbox from './Textbox.vue'

export default {
  components: {
    Textbox,
  },

  props: {
    error: String,
    submitButtonText: String,
    submitButtonType: String,
  },

  data() {
    return {
      disabled: true,
      password: '',
      passwordVisible: false,
      confirmationError: '',
      confirmPassword: '',
      confirmPasswordVisible: false,
      requirements: [
        { complete: false, label: '8 or more characters' },
        { complete: false, label: 'At least 1 Number' },
        { complete: false, label: 'At least 1 Uppercase letter' },
        { complete: false, label: 'At least 1 Special Character (!@#$%)' },
      ],
    }
  },

  watch: {
    password: function (value) {
      this.requirements.forEach((requirement) => {
        switch (requirement.label) {
          case '8 or more characters':
            requirement.complete = value.length >= 8
            break
          case 'At least 1 Number':
            requirement.complete = /\d/.test(value)
            break
          case 'At least 1 Uppercase letter':
            requirement.complete = /[A-Z]/.test(value)
            break
          case 'At least 1 Special Character (!@#$%)':
            requirement.complete = /[!@#$%^&*(),.?":{}|<>]/.test(value)
            break
        }
      })
    },

    confirmPassword: function (value) {
      if (this.requirements.filter((requirement) => requirement.complete).length == this.requirements.length) {
        this.disabled = !value || this.password !== value
      }
    },
  },

  methods: {
    checkPasswordConfirmation: function (value) {
      this.confirmationError = ''
      if (this.requirements.filter((requirement) => requirement.complete).length == this.requirements.length) {
        this.$nextTick(() => {
          this.confirmationError = value && this.password !== value ? 'Passwords do not match.' : ''
        })
      }
    },

    test: function () {
      this.passwordVisible = !this.passwordVisible
    },
  },
}
</script>
